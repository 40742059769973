import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ContentContainer from "../../../components/contentContainer"
import { getJobs, getJobwallImageUrl } from "../../../helpers/apiService"
import { findSimilarJobs } from "../../../helpers/utils";
import JobDetailComponent from "../../../components/jobDetailComponent";

class JobDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      job: {},
      similarJobs: [],
      allJobs: [],
      error: false,
      loading: true,
      currentJobId: 0,
    }
  }

  findJobById(jobId, jobs) {
    let jobForId
    jobs.forEach(job => {
      if(job.id == jobId) {
        jobForId =  job
      }
    });
    return jobForId
  }

  componentDidMount() {
    this.setCurrentJob()
  }

  componentDidUpdate() {
    if(this.props.params.id !== this.state.currentJobId) {
      this.setState({error: false, loading: true, similarJobs: [], job: {}}, () => this.setCurrentJob())
    }
  }

  setCurrentJob() {
    const state = this.props.location.state
    const jobId = this.props.params.id
    if(state && state.job) {
      this.setState({job: state.job, loading: false, error: false, currentJobId: jobId}) 
    } else {
      if(this.state.allJobs && this.state.allJobs.length === 0) {
        getJobs().then(response => {
          if(response.ok) {
            return response.json()
          } else {
            throw new Error()
          }
        })
        .then(response => {
          const currentJob = this.findJobById(jobId, response)
          const similarJobs = findSimilarJobs(currentJob, response, 300)
          if(currentJob) {
            this.setState({job: currentJob, loading: false, error: false, similarJobs: similarJobs, allJobs: response, currentJobId: jobId}) 
          } else {
            this.setState({loading: false, error: true, allJobs: response, currentJobId: jobId}) 
          }
        })
        .catch(error => {
          this.setState({loading: false, error: true, currentJobId: jobId})
        }) 
      } else {
        const currentJob = this.findJobById(jobId, this.state.allJobs)
        const similarJobs = findSimilarJobs(currentJob, this.state.allJobs, 300)
        if(currentJob) {
          this.setState({job: currentJob, loading: false, error: false, similarJobs: similarJobs, currentJobId: jobId}) 
        } else {
          this.setState({loading: false, error: true, currentJobId: jobId}) 
        }
      }
    }
  }

  getJobTypeCode(jobType) {
    if(jobType === "Praktikum") {
      return "INTERN"
    } else if(jobType === "Festanstellung") {
      return "FULL_TIME"
    } 
    return "OTHER"
  }

  render() {
    const { data } = this.props
    const { job, similarJobs, loading, error } = this.state
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={job.jobTitle} jobTitle={job.jobTitle} jobDescription={job.jobDescription} jobDatePosted={job.fromDate} jobValidThrough={job.toDate}
              jobEmploymentType={this.getJobTypeCode(job.jobType)} companyName={job.companyName} companyWebsite={job.companyCareerUrl}
              companyLogoDestination={getJobwallImageUrl(job.companyLogoImage)} companyStreet={job.companyStreet} companyCity={job.companyCity}
              companyPostalCode={job.companyPostalCode} jobIdentifier={job.id + job.jobTitle}/>
        <div className="content">
            <ContentContainer paddingMobile>
              <JobDetailComponent job={job} similarJobs={similarJobs} loading={loading} error={error} ></JobDetailComponent>
            </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default JobDetails

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
